import { TripStatus } from '@/models/trip'
import { TripsByGroups } from '@/store/trips'

export const ONGOING_STATUSES = [TripStatus.ONGOING, TripStatus.ON_HOLD]
export const UPCOMING_STATUSES = [TripStatus.PENDING, TripStatus.ACCEPTED]

export const PAST_RENTING_STATUSES = [
  TripStatus.DECLINED_BY_HOST,
  TripStatus.DECLINED_BY_RENTER,
  TripStatus.CANCELLED_BY_HOST,
  TripStatus.CANCELLED_BY_RENTER,
  TripStatus.FINISHED,
]

export const TRIP_STATUS_GROUP_MAP: Record<
  keyof TripsByGroups,
  TripStatus[]
> = {
  ongoing: ONGOING_STATUSES,
  upcoming: UPCOMING_STATUSES,
  pastRenting: PAST_RENTING_STATUSES,
}
