import client from './index'

import {
  ApiPaginationResponse,
  ApiResponse,
  PaginationParams,
} from '@/models/api'
import {
  FinishTripPaymentPayload,
  Trip,
  TripCancellationRequest,
  TripExtra,
  TripFull,
} from '@/models/trip'
import { User } from '@/models/user'
import { DamageReport, DamageReportStatus } from '@/models/damageReport'
import { TripCheckIn } from '@/models/checkIn'
import { TripCheckOut } from '@/models/checkOut'
import { TripLog } from '@/models/tripLog'

import { GetTripsParams } from '@/store/trips'
import { InsuranceLog } from '@/models/insuranceLog'

type CancellationReqResponse = TripCancellationRequest & {
  trip: Trip
  user: User
}

export const getTrips = (params: PaginationParams & GetTripsParams) => {
  return client.get<ApiPaginationResponse<Trip>>('/trips', {
    params,
  })
}

export const getTripDetails = (id: string) => {
  return client.get<ApiResponse<TripFull>>(`/trips/${id}`)
}

export const updateTripData = (id: string, data: Partial<TripFull>) => {
  return client.put<ApiResponse<TripFull>>(`/trips/${id}`, data)
}

export const updateTripExtra = (id: string, extra: Partial<TripExtra>) => {
  return client.put<ApiResponse<TripExtra>>(`/trips/${id}/extra`, extra)
}

export const createCancellationRequestComment = (
  id: string,
  comment: string
) => {
  return client.post<ApiResponse<CancellationReqResponse>>(
    `/cancel-request/${id}`,
    { admin_comment: comment }
  )
}

export const resolveCancellationRequest = (id: string) => {
  return client.put<ApiResponse<CancellationReqResponse>>(
    `/cancel-request/${id}`
  )
}

export const updateDamageReportStatus = (
  tripId: string,
  reportId: string,
  status: DamageReportStatus
) => {
  return client.put<ApiResponse<DamageReport>>(
    `/trips/${tripId}/damage-report/${reportId}`,
    { status }
  )
}

export const getTripCheckIns = (tripId: string) => {
  return client.get<ApiResponse<TripCheckIn[]>>('/trip-check-in', {
    params: { tripId },
  })
}

export const getTripCheckOuts = (tripId: string) => {
  return client.get<ApiResponse<TripCheckOut[]>>('/trip-check-out', {
    params: { tripId },
  })
}

export const getTripLogs = (tripId: string) => {
  return client.get<ApiResponse<TripLog[]>>('/trip-logs', {
    params: { trip_id: tripId },
  })
}

export const createTripLog = (tripId: string, comment: string) => {
  return client.post<ApiResponse<TripLog>>('/trip-logs', {
    trip_id: tripId,
    comment,
  })
}

export const getInsuranceLogs = (trip_id: string) => {
  return client.get<ApiResponse<InsuranceLog[]>>('/insurance-logs', {
    params: { trip_id },
  })
}

export const createInsuranceLog = (
  car_profile_insurance_request_id: string,
  comment: string
) => {
  return client.post<ApiResponse<InsuranceLog>>('/insurance-logs', {
    car_profile_insurance_request_id,
    comment,
  })
}

export const finishPayment = (
  tripId: string,
  payload: FinishTripPaymentPayload
) => {
  return client.post(`/trips/${tripId}/finish-payment`, payload)
}
