import {
  CanellationPolicyType,
  CarFuelType,
  CarProfileUpdateableKeys,
  CarTransmission,
  CarType,
  OdometerValues,
} from '@/models/carProfile'

import { generateHours, generateYears } from '@/helpers/date'
import { getEnumValues } from '@/helpers/enums'
import { commonCase } from '@/helpers/strings'
import { COUNTRIES } from '@/constants/countries'
import { LANGUAGES } from './languages'

interface Option<T> {
  label: string
  value: T
}

export const BOOLEAN_OPTIONS: Option<boolean>[] = [
  { label: 'true', value: true },
  { label: 'false', value: false },
]

export const BOOLEAN_OPTIONS_POA: Option<boolean | null>[] = [
  { label: 'true', value: true },
  { label: 'false', value: false },
  { label: 'null', value: null },
]

export const TRANSMISSION_OPTIONS: Option<CarTransmission>[] = getEnumValues(
  CarTransmission
).map(value => ({ label: commonCase(value), value }))

export const CAR_FUEL_OPTIONS: Option<CarFuelType>[] = getEnumValues(
  CarFuelType
).map(value => ({ label: commonCase(value), value }))

export const ODOMETER_OPTIONS: Option<OdometerValues>[] = getEnumValues(
  OdometerValues
).map(value => ({ label: value, value }))

export const CART_TYPE_OPTIONS: Option<CarType>[] = getEnumValues(
  CarType
).map(value => ({ label: commonCase(value), value }))

export const CANCELLATION_POLICY_OPTIONS: Option<
  CanellationPolicyType
>[] = getEnumValues(CanellationPolicyType).map(value => ({
  label: commonCase(value),
  value,
}))

export const HOURS_OPTIONS_24 = generateHours().map<Option<string>>(hour => ({
  label: hour,
  value: hour,
}))

export const COUNTRIES_OPTIONS = COUNTRIES.map<Option<string>>(country => ({
  label: `${country.name} ${country.map}`,
  value: country.name,
}))

export const MIN_AGE_OPTIONS = [18, 21, 25, 30, 40].map<Option<number>>(
  age => ({ label: `${age}+`, value: age })
)

export const SHORTES_TRIP_OPTIONS: Option<number>[] = [
  { value: 1440, label: '1 day' },
  { value: 2880, label: '2 days' },
  { value: 4320, label: '3 days' },
  { value: 7200, label: '5 days' },
  { value: 10080, label: '1 week' },
  { value: 44640, label: '1 month' },
]

export const LONGEST_TRIP_OPTIONS: Option<number>[] = [
  { value: 2880, label: '2 days' },
  { value: 4320, label: '3 days' },
  { value: 7200, label: '5 days' },
  { value: 10080, label: '1 week' },
  { value: 44640, label: '1 month' },
  { value: Number.MAX_SAFE_INTEGER, label: 'any' },
]

export const EXTRA_INSURANCE_AVAILABLE_OPTIONS: Option<boolean>[] = [
  { label: 'Allowed', value: true },
  { label: 'Not allowed', value: false },
]

export const ADVANCE_NOTICE_OPTIONS = [
  '1 hour',
  '2 hours',
  '3 hours',
  '6 hours',
  '12 hours',
  '1 day',
  '2 days',
  '3 days',
  '5 days',
  '7 days',
].map<Option<string>>(value => ({ value, label: value }))

export const CAR_YEARS_OPTIONS: Option<number>[] = generateYears(
  100
).map(year => ({ label: `${year}`, value: year }))

export const LANGUAGE_OPTIONS = LANGUAGES.map<Option<string>>(({ name }) => ({
  label: name,
  value: name,
}))

type GetOptionOutput = Option<
  | CarTransmission
  | CarFuelType
  | OdometerValues
  | CarType
  | boolean
  | string
  | number
>
export function getOptionsByCarKey(
  key: CarProfileUpdateableKeys
): GetOptionOutput[] {
  switch (key) {
    case 'transmission':
      return TRANSMISSION_OPTIONS

    case 'fuel_type':
      return CAR_FUEL_OPTIONS

    case 'odometer':
      return ODOMETER_OPTIONS

    case 'car_type':
      return CART_TYPE_OPTIONS

    case 'opt_out_from_in_app_insurance_service':
    case 'no_branded_title':
      return BOOLEAN_OPTIONS

    case 'identity_country':
      return COUNTRIES_OPTIONS

    case 'host_availability_end':
    case 'host_availability_start':
      return HOURS_OPTIONS_24

    case 'min_age_requirement':
      return MIN_AGE_OPTIONS

    case 'advance_notice':
      return ADVANCE_NOTICE_OPTIONS

    case 'shortest_possible_trip':
      return SHORTES_TRIP_OPTIONS

    case 'longest_possible_trip':
      return LONGEST_TRIP_OPTIONS

    case 'cancellation_policy_type':
      return CANCELLATION_POLICY_OPTIONS
    case 'is_extra_insurance_available':
      return EXTRA_INSURANCE_AVAILABLE_OPTIONS

    default:
      return []
  }
}
